import React from "react"
import Wrapper from "../Wrapper"
import Row from "../Row"
import Column from "../Column"

import { Body, H3, BodySmall, H2 } from "../Typography"

import styles from "./index.module.scss"
import Link from "../Link"

type Props = {
  title: string
  address: {
    title: string
    street: string
    street2: string
    city: string
    postal: string
  }
  phone: {
    title: string
    number: string
    tollFree: string
    fax: string
  }
  staff: Array<{
    name: string
    role: string
    email: string
  }>
}

const ContactPageContent = ({ title, address, phone, staff }: Props) => (
  <Wrapper className={styles.pageContent} component="section">
    <Row center>
      <Column mobile={8} desktop={14}>
        <H2 className={styles.title} component="h1">
          {title}
        </H2>
        <Row className={styles.contactRow}>
          <Column mobile={8} desktop={3} className={styles.contactAddress}>
            <Body fontWeight="bold">{address.title}</Body>
            <BodySmall>{address.street}</BodySmall>
            <BodySmall>{address.street2}</BodySmall>
            <BodySmall>{address.city}</BodySmall>
            <BodySmall>{address.postal}</BodySmall>
          </Column>
          <Column mobile={8} desktop={3}>
            <Body fontWeight="bold">{phone.title}</Body>
            <BodySmall>{phone.number}</BodySmall>
            <BodySmall>{phone.tollFree}</BodySmall>
            <BodySmall>{phone.fax}</BodySmall>
          </Column>
        </Row>
        {staff.map((staff, i) => (
          <Row className={styles.staffRow} key={`staff-${i}`}>
            <Column mobile={8} desktop={6}>
              <H3 component="h2">{staff.name}</H3>
              <Body>{staff.role}</Body>
              <Body>
                Email:{"  "}
                <Link to={`mailto:${staff.email}`}>{staff.email}</Link>
              </Body>
            </Column>
          </Row>
        ))}
      </Column>
    </Row>
  </Wrapper>
)

export default ContactPageContent
