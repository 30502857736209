import React from "react"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import ContactPageContent from "../components/ContactPageContent"

const props = {
  title: "Contact us",
  address: {
    title: "Our Address",
    street: "#429, HWY 516",
    street2: " P.O. Box 1327",
    city: "Sioux Lookout, ON",
    postal: "P8T 1B8",
  },
  phone: {
    title: "Call or Fax",
    number: "TEL: 		807-737-1700",
    tollFree: "Toll Free:	1-888-949-9300",
    fax: "807-737-1701",
  },
  staff: [
    {
      name: "V. Geneva Sainnawap",
      role: "Executive Director",
      email: "ahshawahbin@slktvs.ca",
    },
    {
      name: "Shauntell Vincent",
      role: "Victim Support Worker",
      email: "vsupport@slktvs.ca",
    },
  ],
}
const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <ContactPageContent {...props} />
    </Layout>
  )
}

export default ContactPage
